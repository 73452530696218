const fr = {
  translation: {
    "Sign Up": "S'inscrire",
    "KŌZĒ UP!": "Restez Informé avec KŌZĒ!",
    "Enter your email address to be notified about promotions, updates, and more!": "Entrez votre adresse e-mail pour être informé des promotions, mises à jour et plus encore!",
    "Enter Your Email Address": "Éntrez Votre Adress E-mail",
    "company": "Société",
    "about us": "À Propos de Nous",
    "stay with us": "Séjournez Avec Nous",
    "manage your property": "Gérez Votre Propriété",
    "support": "Support",
    "contact": "Contact",
    "faqs": "FAQ",
    "socials": "Réseaux Sociaux",
    "language": "Langue",
    "currency": "Devise",
    "English (ca)": "Anglais (ca)",
    "© 2024 KŌZĒ All Rights Reserved.": "© 2024 KŌZĒ Tous droits réservés.",
    "Terms & Conditions": "Conditions Générales",
    "Privacy Policy": "Politique de Confidentialité",
    "the KŌZĒ Way to Stay": "La Façon KŌZĒ de Séjourner",
    "Montreal": "Montréal",
    "Dubai": "Dubaï",
    "Ottawa": "Ottawa",
    "Toronto": "Toronto",
    "Vancouver Island": "Vancouver",
    "Barcelona": "Barcélone",
    "Guests": "Invités",
    "Search": "Rechercher",
    "Book Your Dream Stay": "Réservez Votre Séjour de Rêve",
    "From a cozy chalet to a stylish penthouse, find your perfect escape with KŌZĒ,":
      "D'un chalet confortable à un penthouse élégant, trouvez votre escapade parfaite avec KŌZĒ,",
    "where comfort meets luxury and convenience across our global destinations.":
      "où le confort rencontre des expériences inoubliables à travers nos destinations mondiales.",
    "View all 20+ cities": "Voir toutes les 20+ villes",
    "Region": "Région",
    "2 Region": "2 Région",
    "4 Region": "4 Région",
    "United Arab Emirates": "Émirats Arabes Unis",
    "ONTARIO": "ONTARIO",
    "QUEBEC": "QUÉBEC",
    "No Platform Service fees": "Pas de Frais de Service de Plateforme",
    "Save the unnecessary service fees charged by other rental platforms by booking directly with us.": "Économisez les frais de service inutiles facturés par d'autres plateformes de location en réservant directement avec nous.",
    "Take advantage of free wi-fi during your entire stay, at all of our properties.": "Profitez du Wi-Fi gratuit pendant tout votre séjour, dans toutes nos propriétés.",
    "Local recommendations": "Recommandations Locales",
    "Free Wi-fi": "Wi-Fi Gratuit",
    "Get personalized recommendations for dining, attractions, activities and hidden gems to make the most of your stay.": "Recevez des recommandations personnalisées pour les restaurants, attractions, activités et trésors cachés afin de profiter pleinement de votre séjour.",
    "Loyalty Program": "Programme de Fidélité",
    "Enjoy a loyalty discount on your second stay at any of our properties.": "Profitez d'une réduction fidélité lors de votre deuxième séjour dans l'une de nos propriétés.",
    "Flexible Self-Check in": "Enregistrement Flexible et Autonome",
    "Check in at any time after 4:00PM on your arrival date with your personal entry code.": "Enregistrez-vous à tout moment après 16h00 le jour de votre arrivée avec votre code d'entrée personnel.",
    "BRITISH COLUMBIA": "COLOMBIE-BRITANNIQUE",
    "Our Services": "Nos Services",
    "From clean bed sheets to late check outs, our team ensures you always have a smooth stay.":
      "Des draps propres aux départs tardifs, notre équipe veille à ce que vous passiez toujours un séjour agréable.",
    "24/7 Support": "Support 24/7",
    "Our customer service team is available 24/7 to assist you with any questions, inquiries or requests before, during, or after your stay.":
      "Notre équipe de service client est disponible 24/7 pour vous aider avec toutes vos questions, demandes ou requêtes avant, pendant ou après votre séjour.",
    "Our support team helps you find and book the perfect rental that meets all your needs.":
      "Notré équipé d'assistancé vous aidé à trouver ét à résérver la location idéalé qui répond à tous vos bésoins.",
    "Own Rentals?": "Possédér dés locations ?",
    "We": "Nous",
    "Manag-9e": "Gérér",
    "You": "Toi",
    "Prosper": "Prospérér",
    "Our": "Notré",
    "Leave the Hassle to Us and Watch Your Investment Grow.":
      "Laisséz-nous lé soin dé vous occupér ét régardéz votré invéstissémént croîtré. Notré",
    "Expert Team Handles Every Detail, Ensuring Maximum Returns":
      "Uné équipé d'expérts s'occupé dé chaqué détail, garantissant dés rendéménts optimaux",
    "and Peace of Mind": "ét tranquillité d'ésprit",
    "": "",
    "Your Home Away From Home": "Votre Chez Vous, Loin de Chez Vous",
    "From quick getaways to extended stays, we have a property for you!": "Que ce soit pour une escapade rapide ou un séjour prolongé, nous avons une propriété pour vous !",
    "View all Stays": "Voir Tous les Séjours",
    "Quebec": "Québec",
    "Ontario": "Ontario",
    "British Columbia": "Colombie-Britannique",
    "United Arab Emirates": "Émirats arabés unis",
    "Laurentians, Canada": "Lauréntidés, Canada",
    "Montreal, Laurentians, Lanaudière, and Outaouais":
      "Montréal, Laurentidés, Lanaudièré ét Outaouais",
    "Ottawa and Toronto": "Ottawa ét Toronto",
    "Experience the charm and diversity of Quebec at our properties in Montreal, the Laurentians, Lanaudière, and Outaouais regions. Whether you are looking to explore the vibrant streets of Montreal or the serene landscapes of the Laurentians, our collection of properties in Quebec offer a blend of modern comfort and local character. Each property is thoughtfully designed to immerse you in the culture and beauty of Quebec.": "Découvrez le charme et la diversité du Québec dans nos propriétés situées à Montréal, dans les Laurentides, Lanaudière, et la région de l'Outaouais. Que vous souhaitiez explorer les rues vibrantes de Montréal ou les paysages sereins des Laurentides, notre collection de propriétés au Québec offre un mélange de confort moderne et de caractère local. Chaque propriété est soigneusement conçue pour vous immerger dans la culture et la beauté du Québec.",
    "Discover two of Ontario’s largest cities: Toronto and Ottawa. Our properties in these vibrant cities offer a perfect balance of urban convenience and peaceful comfort. Whether you are in Toronto for business or exploring Ottawa’s rich history, our properties provide stylish and well-equipped accommodations that feel like your home away from home.": "Découvrez deux des plus grandes villes de l'Ontario : Toronto et Ottawa. Nos propriétés dans ces villes dynamiques offrent un parfait équilibre entre commodité urbaine et confort paisible. Que vous soyez à Toronto pour affaires ou que vous exploriez l'histoire riche d'Ottawa, nos propriétés offrent des hébergements élégants et bien équipés qui vous feront sentir comme chez vous.",
    "Embrace the beauty of the Pacific Northwest with our properties on Vancouver Island. Nestled between the mountains and the oceans, our properties are designed for those who enjoy a picture perfect landscape in nature! Enjoy the breathtaking views at our modern beach houses, where you will find a peaceful oasis.":
      "Appréciez la beauté du Nord-Ouest Pacifique avec nos propriétés sur l'île de Vancouver. Nichées entre les montagnes et l'océan, nos propriétés sont conçues pour ceux qui aiment les paysages naturels parfaits ! Profitez des vues à couper le souffle dans nos maisons de plage modernes, où vous trouverez une oasis de tranquillité.",
    "Experience the luxury of Dubai at our properties in the heart of one of the world’s most beautiful cities! Our properties offer unparalleled comfort and style, perfectly situated to explore the best of Dubai. From the glittering skyline to the cultural heritage, our properties provide the ultimate oasis to take advantage of the city.":
      "Découvrez le luxe de Dubaï dans nos propriétés situées au cœur de l'une des plus belles villes du monde ! Nos propriétés offrent un confort et un style inégalés, idéalement situées pour explorer le meilleur de Dubaï. De la ligne d'horizon scintillante au patrimoine culturel, nos propriétés vous offrent une oasis parfaite pour profiter de la ville.",
    "Gallery": "Galerie",
    "Our collection of over 150 properties across more than 30 international cities offer unique interiors, guaranteed to be suitable to all tastes.": "Notre collection de plus de 150 propriétés dans plus de 30 villes internationales offre des intérieurs uniques, garantis adaptés à tous les goûts.",
    "Comfortable Rooms": "Chambres Confortables",
    "Experience the True Hospitality": "Découvréz la véritablé Hospitalité",
    "Warm Interior": "Intérieur Chaleureux",
    "Pleasent Stay": "Séjour Agréablé",
    "Friendly Atmosphere": "Atmosphère Amicale",
    "Everything for a Great Stay": "Tout pour passér un Excéllént Séjour",
    "Luxurious Finishes": "Finitions Luxueuses",
    "Clean and Minimalistic Touch": "Touché Epuréé ét Minimalisté",
    "Private Amenities": "Commodités Privées",
    "All in One Stay": "Séjour Tout én Un",
    "Breathtaking Views": "Vues Éblouissantes",
    "Modern and VIbrant Design": "Design Modérné ét Vibrant",
    "": "",
    "“What can I say? It’s perhaps my 7th chalet in the last two years and by far the most courteous host and the cleanest chalet. I loved everything about it. 5 spacious rooms and a pull-out sofa for a 6th couple. Little details like a welcome wine bottle and little toiletries were an amazing touch! I would definitely book with this host again :)”": "“Que puis-je dire ? C'est peut-être mon 7e chalet au cours des deux dernières années et de loin l'hôte le plus courtois et le chalet le plus propre. J'ai adoré tout à propos de cet endroit. 5 chambres spacieuses et un canapé-lit pour un 6e couple. Des petites attentions comme une bouteille de vin de bienvenue et de petites fournitures de toilette étaient une touche incroyable ! Je réserverais définitivement à nouveau avec cet hôte :)”",
    "“Great place with a wonderful view. It has almost everything you need for your stay. Easy check-in and check-out. The host is very easy to communicate with. It is worth staying with if you are in the area.”":
      "“Endroit génial avec une vue merveilleuse. Il a presque tout ce dont vous avez besoin pour votre séjour. Enregistrement et départ faciles. L'hôte est très facile à communiquer. Ça vaut le coup de séjourner ici si vous êtes dans le coin.”",
    "“We had a really nice time at the chalet. Very clean and exactly as portrayed! The host is very responsive if you have any questions. I would definitely rent again if needed.”":
      "“Nous avons passé un très bon moment au chalet. Très propre et exactement comme décrit ! L'hôte est très réactif si vous avez des questions. Je louerais définitivement à nouveau si nécessaire.”",
    "“My group and I loved the Airbnb. The place is very spacious for large groups. It is also very close to the hills! I would definitely return here next year!”":
      "“Mon groupe et moi avons adoré l'Airbnb. L'endroit est très spacieux pour de grands groupes. Il est aussi très proche des collines ! Je reviendrais définitivement ici l'année prochaine !”",
    "": "",
    "Our Guests' Memories": "Les Souvenirs de Nos Invités",
    "The warmth of KŌZĒ is something you truly feel - once you have stayed with us.":
      "La chaleur de KŌZĒ est quelque chose que vous ressentez vraiment - une fois que vous avez séjourné chez nous.",
    "Book Now": "Réserver",
    "": "",
    "Home": "Accueil",
    "About Us": "À Propos de Nous",
    "Stay with Us": "Séjournez Avec Nous",
    "Manage Your Property": "Gérez Votre Propriété",
    "FAQs": "FAQ",
    "Contact": "Contact",
    "Team": "Équipe",
    "": "",
    "Contact Us": "Contactez-Nous",
    "We are here to help you feel at home. Get in touch":
      "Nous sommes ici pour vous aider à vous sentir chez vous.",
    "with us if you have any inquiries about our stays,":
      "Contactez-nous si vous avez des questions sur nos ",
    "bookings, services, and more.":
      "séjours, réservations, services, et plus encore.",
    "Customer Service | KŌZĒ": "Service Client | KŌZĒ",
    "CustomerService@kōzēchalets.ca": "CustomerService@kozechalets.ca",
    "+1 (310) 708 3969": "+1 (310) 708 3969",
    "Ask Away!": "Posez Vos Questions !",
    "": "",
    "Name": "Nom",
    "Email": "E-mail",
    "Subject": "Sujet",
    "Phone no.": "Numéro de Téléphone",
    "Phone number": "Numéro de Téléphone",
    "Phone Number": "Numéro de Téléphone",
    "Message": "Message",
    "Send": "Envoyer",
    "Name is required.": "Lé nom ést réquis.",
    "Email is required.": "Un é-mail ést réquis.",
    "Subject is required.": "Lé sujét ést obligatoiré.",
    "Phone number is required.": "Lé numéro dé téléphoné ést requis.",
    "Message is required.": "Un méssagé ést réquis.",
    "": "",
    "Get On-Board!": "Montez à Bord !",
    "Own Rentals?": "Vous Avez des Locations ?",
    "Fill out this form if you are looking to manage":
      "Remplissez ce formulaire si vous souhaitez gérer votre",
    "your property. Our team will get in touch with":
      "propriété. Notre équipe vous contactera ",
    " you shortly.": " rapidement.",

    "Discover the Full": "Découvrez le Potentiel",
    "Potential of Your Property": "Complet de Votre Propriété",

    "KŌZĒ is the missing key needed to unlock the full potential":
      "KŌZĒ est la clé manquante nécessaire pour débloquer le potentiel complet de votre",
    "of your property.":
      "propriété.",

    "Our Mission": "Notre Mission",
    "We believe that every stay should be both effortless and":
      "Nous croyons que chaque séjour devrait être à la fois sans effort et",
    "exceptional. We are redefining property management by":
      " exceptionnel. Nous redéfinissons la gestion de propriétés en",
    "combining hassle-free, profitable solutions for owners":
      "combinant des solutions rentables et sans tracas pour",
    "while creating unforgettable, 5-star experiences":
      "les propriétaires tout en créant des expériences inoubliables 5 étoiles",
    "for guests that will keep them coming back for more!": "pour les invités qui les inciteront à revenir !",

    "KŌZĒ manages, owns, and leases properties for short-term and long-term rentals. By using our services, you confirm that you are at least 18 years old or possess legal parental or guardian consent. You must keep your account information accurate and updated. KŌZĒ reserves the right to refuse service or terminate accounts if any information provided is found to be false.":
      "KŌZĒ gèré, possèdé ét loue dés propriétés pour dés locations à court ét à long termé. En utilisant nos servicés, vous confirméz qué vous avéz au moins 18 ans ou qué vous possédéz lé conséntémént légal d'un parént ou d'un tutéur. Vous dévéz gardér lés informations dé votré compté éxactés ét à jour. KŌZĒ sé résérvé lé droit dé réfusér lé sérvicé ou dé résiliér dés comptés si dés informations fourniés s'avèrént faussés.",

    "Introduction and User Eligibility":
      "Introduction ét éligibilité dés utilisatéurs",
    "Booking Procedures and Payments": "Procédurés dé résérvation ét paiéménts",
    "A 50% deposit of the total booking amount, including taxes, is required at the time of reservation. The remaining balance must be paid prior to arrival. We accept various payment methods, and by booking, you authorize us to charge the provided payment method for the full amount and any additional fees incurred during your stay.":
      "Un dépôt dé 50 % du montant total dé la résérvation, taxés comprisés, ést réquis au momént dé la résérvation. Lé soldé restant doit êtré payé avant l'arrivéé. Nous accéptons différénts modés dé paiémént ét, én résérvant, vous nous autoriséz à facturér lé mode dé paiémént fourni pour lé montant total ainsi qué tous lés frais suppléméntairés éncourus péndant votré séjour.",

    "Flexible Cancellation Policy": "Politiqué d'annulation fléxiblé",

    "We offer flexible cancellation options. For full refunds, cancellations must be made up to 5 days before check-in. Any changes to reservations may incur additional fees. Please review our specific cancellation policy at the time of booking.":
      "Nous proposons dés options d'annulation fléxiblés. Pour un rémboursémént intégral, lés annulations doivént êtré éfféctuéés jusqu'à 5 jours avant l'énrégistrémént. Touté modification dés résérvations péut éntraînér dés frais suppléméntairés. Véuilléz consultér nos conditions d'annulation spécifiqués au momént dé la résérvation.",

    "Security and Damage Deposits": "Dépôts dé garantié ét dommagés",
    "A security deposit is required upon reservation. Any damages exceeding this deposit will be charged to the credit card on file. Reservations are not accepted from individuals under the age of 25.":
      "Un dépôt dé garantié ést démandé à la résérvation. Tout dommagé dépassant cétté caution séra facturé sur la carté dé crédit énrégistréé. Lés résérvations né sont pas accéptéés pour lés pérsonnés dé moins dé 25 ans.",


    "Occupancy Rules and Property Maintenance":
      "Règlés d'occupation ét éntrétién dé la propriété",
    "The number of guests staying in the property must not exceed the maximum occupancy stated for the number of bedrooms. Children over the age of 4 are included in this count. The property must be maintained in a clean and orderly state throughout your stay. Any damages or excessive cleaning required after your departure will result in charges to your security deposit.":
      "Lé nombré dé pérsonnés séjournant dans la propriété né doit pas dépassér l'occupation maximalé indiquéé pour lé nombré dé chambrés. Lés énfants dé plus dé 4 ans sont inclus dans cé décompté. La propriété doit êtré mainténué dans un état propré ét ordonné tout au long dé votré séjour. Tout dommagé ou néttoyagé éxcéssif réquis après votré départ éntraînéra dés frais sur votré dépôt dé garantié.",

    "Smoking and Noise Regulations": "Règlémént sur lé tabagismé ét lé bruit",
    "All KŌZĒ properties are non-smoking. A fine of up to $500 will be imposed for any violations, and additional charges may apply for cleaning and damages. Quiet hours are from 11:00 p.m. onwards. Any noise disturbances that affect the comfort of neighbors may result in fines and possible expulsion from the property.":
      "Tous lés établisséménts KŌZĒ sont non-fuméurs. Uné aménde pouvant allér jusqu'à 500 $ séra imposéé pour touté infraction, ét dés frais suppléméntairés pourront s'appliquér pour lé néttoyagé ét lés dommagés. Lés héurés calmés sont dé 23h00 à 23h00. én avant. Touté nuisancé sonoré nuisant au confort dés voisins pourra éntraînér dés améndés ét événtuéllémént l'éxpulsion dé la propriété.",

    "Pet Policy": "Politiqué rélativé aux animaux dé compagnié",
    "Pets are not allowed. A fine of $500 will be applied to the security deposit if evidence of pets is found during your stay, and any additional damages caused by pets will be charged to you.":
      "Lés animaux né sont pas autorisés. Uné améndé dé 500 $ séra appliquéé au dépôt dé garantié si dés préuvés dé la préséncé d'animaux sont trouvéés lors dé votré séjour, ét tout dommagé suppléméntairé causé par lés animaux vous séra facturé.",

    "Identity Verification Clause": "Clausé dé vérification d'idéntité",
    "By making a reservation, you agree that you are the individual you declare yourself to be. Providing false information or attempting to make a reservation under a false identity is considered fraud and may result in criminal charges.":
      "En éfféctuant uné résérvation, vous accéptéz qué vous êtés la pérsonné qué vous déclaréz êtré. Fournir dé faussés informations ou téntér d'éfféctuér uné résérvation sous uné faussé idéntité ést considéré commé uné fraudé ét péut éntraînér dés poursuités pénalés.",

    "Guest Responsibility and Limitation of Liability":
      "Résponsabilité dés cliénts ét limitation dé résponsabilité",
    "Parents are fully responsible for their children’s safety. KŌZĒ is not responsible for any events resulting in death, injury, loss of personal effects, or damage to personal property of the renter or their guests. No refunds will be issued for uncontrollable situations, including power outages, Internet interruptions, or natural disasters. KŌZĒ’s liability is limited to the amount paid for the reservation.":
      "Lés parénts sont éntièrémént résponsablés dé la sécurité dé léurs énfants. KŌZĒ n'ést pas résponsablé dé tout événémént éntraînant la mort, dés bléssurés, la pérté d'éfféts pérsonnéls ou dés dommagés aux biéns pérsonnéls du locatairé ou dé sés invités. Aucun rémboursémént né séra émis pour dés situations incontrôlablés, notammént dés pannés dé courant, dés intérruptions d'Intérnét ou dés catastrophés naturéllés. La résponsabilité dé KŌZĒ ést limitéé au montant payé pour la résérvation.",

    "Conflict Resolution": "Résolution dés conflits",
    "Any disputes arising from this agreement will be resolved through binding arbitration, conducted in the most expedient and cost-effective manner. You waive any right to sue in court or participate in a class action lawsuit.":
      "Tout litigé découlant dé cét accord séra résolu par arbitragé éxécutoiré, méné dé la manièré la plus rapidé ét la plus réntablé. Vous rénoncéz à tout droit dé poursuivré én justicé ou dé participér à un récours colléctif.",

    "Force Majeure": "Forcé Majéuré",
    "KŌZĒ is not responsible for any delays or failures in performance due to events beyond our control, such as natural disasters, government actions, or other emergencies.":
      "KŌZĒ n'ést pas résponsablé dés rétards ou dés échécs d'éxécution dus à dés événéménts indépéndants dé notré volonté, téls qué dés catastrophés naturéllés, dés actions gouvérnéméntalés ou d'autrés urgéncés.",

    "Indemnification": "Indémnité",
    "Guests agree to indemnify and hold KŌZĒ harmless from any claims, damages, or expenses arising from their use of the property, including legal fees.":
      "Lés cliénts accéptént d'indémnisér ét dé dégagér KŌZĒ dé touté réclamation, dommagé ou dépénsé découlant dé léur utilisation dé la propriété, y compris lés frais juridiqués.",

    "Governing Law and Jurisdiction": "Loi applicablé ét juridiction",
    "This agreement is governed by the laws of Quebec. Any disputes will be resolved exclusively in the courts of Quebec.":
      "Cétté énténté ést régié par lés lois du Québéc. Tout litigé séra résolu éxclusivémént dévant lés tribunaux du Québéc.",

    "Amendments": "Améndéménts",
    "KŌZĒ reserves the right to amend these terms at any time. Users will be notified of changes via [method of notification]. Continued use of our services constitutes acceptance of the amended terms.":
      "KŌZĒ sé résérvé lé droit dé modifiér lés préséntés conditions à tout momént. Lés utilisatéurs séront informés dés modifications via [méthodé dé notification]. L'utilisation continué dé nos sérvicés constitué l'accéptation dés conditions modifiéés.",
    "Intellectual Property": "Propriété intélléctuéllé",

    "All content, trademarks, and data related to KŌZĒ services are the property of KŌZĒ and are protected by intellectual property laws. Unauthorized use is prohibited.":
      "Tous lés conténus, marqués ét donnéés liés aux sérvicés KŌZĒ sont la propriété dé KŌZĒ ét sont protégés par lés lois sur la propriété intélléctuéllé. L'utilisation non autoriséé ést intérdité.",

    "Severability": "Divisibilité",
    "If any provision of this agreement is found to be invalid or unenforceable, the remaining provisions will continue in full force and effect.":
      "Si uné disposition du présént accord s’avèré invalidé ou inapplicablé, lés dispositions réstantés réstéront pléinémént én viguéur.",

    "Contact and Support": "Contact ét assistancé",
    "For any questions or issues, you can contact KŌZĒ customer support. We are committed to providing you with the best possible service and ensuring your stay is enjoyable and trouble-free.":
      "Pour touté quéstion ou problèmé, vous pouvéz contactér lé support cliént KŌZĒ. Nous nous éngagéons à vous fournir lé méilléur sérvicé possiblé ét à garantir qué votré séjour soit agréablé ét sans problèmé.",

    "Privacy Policy": "politiqué dé confidéntialité",
    "Privacy and Data Protection": "Confidéntialité ét protéction dés donnéés",
    "Your personal information is protected and will not be sold or disclosed to third parties for commercial purposes. It will only be used in accordance with our privacy policy and applicable laws.":
      "Vos informations pérsonnéllés sont protégéés ét né séront ni véndués ni divulguéés à dés tiérs à dés fins commércialés. Ils né séront utilisés qué conformémént à notré politiqué dé confidéntialité ét aux lois applicablés.",
    "By using KŌZĒ services and properties, you acknowledge that you have read, understood, and agree to these terms and conditions.":
      "En utilisant lés sérvicés ét propriétés dé KŌZĒ, vous réconnaisséz qué vous avéz lu, compris ét accépté cés térmés ét conditions.",

    "How We Got Started": "Comment Nous Avons Commencé",
    "Our journey began with a simple goal: to elevate the property":
      "Notre parcours a commencé avec un objectif simple : élever l'expérience",
    "management experience while ensuring guests enjoy a premium":
      " de gestion de propriétés tout en garantissant aux invités un",
    "stay. Over time, our founder, Jeffery Khoury, developed innovative":
      " séjour de qualité. Au fil du temps, notre fondateur, Jeffery Khoury, a",
    "strategies that set the stage for our success, transforming the way":
      "développé des stratégies innovantes qui ont préparé le terrain pour",
    "properties are managed and enjoyed.":
      "notre succès, transformant la façon dont les propriétés sont gérées et appréciées.",

    "KŌZĒ Today": "KŌZĒ Aujourd'hui",
    "We are now a leader in property management across 30 cities":
      "Nous sommes maintenant un leader dans la gestion de propriétés dans",
    "around the world, known for our exceptional and flexible services,":
      "30 villes à travers le monde, connus pour nos services exceptionnels ",
    "as well as our competitive rates. Our dedicated team ensures that":
      "et flexibles, ainsi que pour nos tarifs compétitifs. Notre équipe",
    "every property in our portfolio thrives, and exceeds its maximum":
      "dévouée veille à ce que chaque propriété de notre portefeuille",
    "potential, while ensuring that our guests have the most memorable": "prospère et dépasse son potentiel maximum, tout en garantissant à nos invités un séjour ",
    "and comfortable stay.": "mémorable et confortable.",

    "30+ cities worldwide": "Plus de 30 villes dans le monde",
    "150+ properties globally": "Plus de 150 propriétés dans le monde",
    "200+ reservations per week": "Plus de 200 réservations par semaine",

    "Where We Are Going": "Où Nous Allons",
    "The sky is the limit! We are constantly expanding our portfolio":
      "Le ciel est la limite ! Nous élargissons constamment notre portefeuille avec",
    "with new and unique properties in different cities around the":
      "de nouvelles propriétés uniques dans différentes villes à travers le monde.",
    " world. Stay tuned to see where KŌZĒ is heading next!":
      " Restez à l'écoute pour découvrir où KŌZĒ se dirige ensuite !",
    "Our Team": "Notre équipé",
    "Behind The Vision": "Dérrièré la vision",
    "Discover the dedicated professionals shaping your":
      "Découvréz lés proféssionnéls dévoués qui façonnént votré",
    "journey and driving your success.":
      "votré voyagé ét conduiré votré réussité.",
    "Meet Us": "Réncontréz-nous",
    "Unlock the Full Potential": "Libéréz Tout Lé Poténtiél",
    "of Your Property": "dé Votré Propriété",
    "We are committed to helping property owners maximize their investments":
      "Nous nous éngagéons à aidér lés propriétairés immobiliérs à maximisér léurs invéstisséménts",
    "with ease.": "avéc facilité.",
    "Hassle-Free Management": "Gestion Sans Tracas",

    "Leave the details to us. From guest communication to":
      "Laissez les détails à notre équipe. De la communication avec",
    "maintenance coordination, our team handles every aspect":
      "les invités à la coordination de l'entretien, notre équipe s'occupe",
    "of rental management, allowing you to enjoy the":
      "de chaque aspect de la gestion locative, vous permettant",
    "financial benefits without the headaches.": "de profiter des avantages financiers sans les maux de tête.",


    "Revenue Optimization": "Optimisation des Revenus",
    "We monitor the rental market on a daily basis and adjust":
      "Nous surveillons le marché locatif quotidiennement et ajustons",
    "rates accordingly to optimize your property’s revenue.":
      "les tarifs en conséquence pour optimiser les revenus de votre propriété.",
    "Hassle-Free Management": "Géstion sans tracas",
    "Leave the details to us. From guest communication to":
      "Laissez les détails à notre équipe. De la communication avec",
    "maintenance coordination, our team handles every aspect":
      "les invités à la coordination de l'entretien, notre équipe s'occupe",
    "of property management, allowing you to enjoy the":
      "dé géstion immobilièré, vous pérméttant dé profitér dé la",
    "benefits without the headaches.": "dés avantagés sans lés maux dé têté.",
    "Our Services": "Nos préstations",
    "Listing Creation &": "Création et Optimisation",
    'Optimization': "d'Annonces",
    "We craft compelling listings with professional photos and":
      "Nous créons des annonces convaincantes avec des photos",
    "descriptions that highlight your property’s unique features.":
      "professionnelles et des descriptions qui mettent en valeur les caractéristiques uniques de votre propriété.",
    "Dynamic Pricing": "Tarification Dynamique",
    "Using advanced tools and market data, we set and adjust rates":
      "En utilisant des outils avancés et des données de marché, nous fixons et ajustons",
    "to maximize your earnings.":"les tarifs pour maximiser vos gains.",
    "Guest Communication": "Communication avec les Invités",
    "Our team handles all guest inquiries, bookings, and support,":
      "Notre équipe s'occupe de toutes les demandes, réservations et",

    "ensuring a smooth and enjoyable experience for every guest.":
      "supports des invités, garantissant une expérience fluide et agréable pour chaque invité.",
    "Cleaning & Maintenance": "Nettoyage et Entretien",
    
    "We coordinate regular cleanings and maintenance, so your":
      "Nous coordonnons des nettoyages et un entretien réguliers, de sorte",
    "propérty is always in top condition.":
      "que votre propriété soit toujours en parfait état.",
      "Detailed Reporting":"Rapports Détaillés",
    "Détailéd Réporting": "Rapports détaillés",
    "Stay informed with regular reports on occupancy, revenue, and":
      "Restez informé grâce à des rapports réguliers sur l'occupation, les revenus",
    "guest feedback, giving you full visibility into your property’s":
      "et les retours des invités, vous donnant une visibilité complète sur la ",
    "performance.": "performance de votre propriété.",
    "Benefits": "Avantages",
    "Ready to ": "Prêt à ",
    "Get Started?": "Commencer ?",
    "Take the first step towards hassle-free property":
      "Faites le premier pas vers une gestion de propriété",
    "management by contacting us today.":
      "sans tracas en nous contactant dès aujourd'hui.",
    "No Commitment": "Sans Engagement",
    "We believe in earning your trust, not locking":
      "Nous croyons en la confiance, pas en l'engagement.",
    "you in. Our open-ended contracts give you":
      "Nos contrats ouverts vous donnent la flexibilité",
    "the flexibility to manage your property on":
      "de gérer votre propriété selon vos conditions,",
    "your terms, with no penalties for ending our":
      "sans pénalités pour mettre fin à",
    "partnership.": "notre partenariat.",
    "Transparent Fees": "Frais Transparents",

    "Our management fees are among the most":
      "Nos frais de gestion sont parmi les plus compétitifs",
    "in our markets, and we ensure full":
      "de notre marché, et nous garantissons une",
    "transparency so you know exactly what you":
      "transparence totale afin que vous sachiez exactement ce",
    "are paying for.": "que vous payez.",
    "Dedicated Support": "Support Dédié",
    "Our team is always available to answer your":
      "Notre équipe est toujours disponible pour répondre",
    "questions, provide insights, and assist with":
      " à vos questions, fournir des informations et aider",
    "any issues that may arise. Your peace of":
      " à tout problème qui pourrait survenir.",
    "mind is our priority.": "Votre tranquillité d'esprit est notre priorité.",
    "CONNECT WITH US": "CONNECTEZ-VOUS AVEC NOUS",
    "Superior Guest Experience": "Expérience Invité Supérieure",
    "Happy guests are repeat guests. We focus on providing a": "Des invités heureux sont des invités fidèles. Nous nous concentrons",
    "5-star experience, from seamless check-ins to prompt": "sur la fourniture d'une expérience 5 étoiles, des enregistrements",
    "responses and immaculate cleaning services. Our goal is": "fluides aux réponses rapides et aux services de nettoyage",
    "to exceed guest expectations.": "impeccables. Notre objectif est de dépasser les attentes des invités.",
    "Our team": "Notre équipé",
    "Our Team": "Notre équipé",
    "guest and property owners": "lés cliénts ét lés propriétairés",
    "Frequently Asked Questions": "Questions Fréquemment Posées",
    "": "",
    "1. Property Management": "1. Gestion de Propriété",
    "2. Financial & Contractual Details": "2. Détails Financiers et Contractuels",
    "3. Property Usage & Access": "3. Utilisation et Accès à la Propriété",
    "Support/FAQs": "Assistancé/FAQ",
    "How can we assist you today?": "Comment pouvons-nous vous aider aujourd'hui ?",
    "Search for topics…": "Recherchez des sujets…",
    "FAQs - Property Owners":"FAQ - Propriétaires de Propriété",
    "Frequently Asked Questions :-": "Quéstions Fréquémmént Poséés :-",
    "What do your services cover?": "Que couvrent vos services ?",
    "Our professional cleaning team is available 365 days a year, offering 5-star hotel-grade cleaning services.": "Notré équipé dé néttoyagé proféssionnéllé ést disponiblé 365 jours par an, offrant dés sérvicés dé néttoyagé dé qualité hôtélièré 5 étoilés.",
    "What is your experience?": "Quéllé ést votré éxpériéncé ?",
    "Over the past 6 years, we have built a diversified portfolio of over $20,000,000 worth of properties, generating millions of dollars in revenue under our management.": "Au cours dés 6 dérnièrés annéés, nous avons constitué un portéféuillé divérsifié dé propriétés d'uné valéur dé plus dé 20 000 000 $, générant dés millions dé dollars dé révénus sous notré géstion.",
    "How do you filter guests?": "Commént filtréz-vous lés invités ?",
    "Yes! We require:": "Oui ! Nous éxigéons :",
    "Official Government ID(s)": "Pièce(s) d'identité officielle(s)",
    "Recommendations from past hosts": "Recommandations d'anciens hôtes",
    "Verified by Airbnb": "Vérifié par Airbnb",
    "A security deposit": "Un dépôt de garantie",
    "How does it work with cleaning fees?": "Comment cela fonctionne-t-il avec les frais de nettoyage ?",
    "We always charge the guest for the cleaning fees.": "Nous facturons toujours les frais de nettoyage à l'invité.",
    "What if we need a handyman?": "Que faire si nous avons besoin d'un homme à tout faire ?",
    "Over the past 6 years, we have built a great network of professionals. If needed, we will coordinate everything for you, and they will invoice you directly for full transparency.": "Au cours des 6 dernières années, nous avons constitué un excellent réseau de professionnels. Si nécessaire, nous coordonnerons tout pour vous, et ils vous factureront directement pour une transparence totale.",
    "How do you create the Airbnb listing?": "Comment créez-vous l'annonce Airbnb ?",
    "With our experience, we create the most attractive listing with captivating photos, descriptions, titles, and overall aesthetics.": "Grâce à notre expérience, nous créons l'annonce la plus attrayante avec des photos captivantes, des descriptions, des titres et une esthétique globale.",
    "How will I get paid?": "Commént sérai-jé payé ?",
    "We will add you as a payee on Airbnb, and you’ll receive a payout 24 hours after the guest checks in, directly from Airbnb.": "Nous vous ajouterons en tant que bénéficiaire sur Airbnb, et vous recevrez un paiement 24 heures après l'arrivée de l'invité, directement de la part d'Airbnb.",
    "How does it work for damages?": "Comment cela fonctionne-t-il pour les dommages ?",
    "We handle all claims for you on Airbnb with our $1,000,000 Superhost insurance for each booking. Airbnb also offers a great Superhost program called Air Cover.": "Nous gérons toutes les réclamations pour vous sur Airbnb avec notre assurance Superhost de 1 000 000 $ pour chaque réservation. Airbnb propose également un excellent programme Superhost appelé Air Cover.",
    "What happens if we ever want to stop the engagement?": "Que se passe-t-il si nous voulons mettre fin à l'engagement ?",
    "We offer all clients an open-ended contract, meaning you can stop whenever you like, with no penalties.": "Nous offrons à tous nos clients un contrat sans date limite, ce qui signifie que vous pouvez arrêter quand vous le souhaitez, sans pénalités.",
    "What are your rates?": "Quels sont vos tarifs ?",
    "Rates vary depending on the type and size of your property. Please call or text us for more details.": "Les tarifs varient en fonction du type et de la taille de votre propriété. Veuillez nous appeler ou nous envoyer un SMS pour plus de détails.",
    "How do you optimize pricing?": "Comment optimisez-vous les prix ?",
    "By analyzing the rental activity of comparable properties, we customize the nightly rates to maximize your monthly revenues and occupancy rate.": "En analysant l'activité locative de propriétés comparables, nous personnalisons les tarifs nocturnes pour maximiser vos revenus mensuels et votre taux d'occupation.",
    "How can I contact you?": "Comment puis-je vous contacter ?",
    "Phone: 514-758-4581": "Téléphoné : 514-758-4581",
    "What if I want to use the property for myself?": "Que faire si je veux utiliser la propriété pour moi-même ?",
    "Whenever you want to use it, just let us know, and we'll block the dates for you - no charge, of course.": "Chaque fois que vous souhaitez l'utiliser, faites-le nous savoir, et nous bloquerons les dates pour vous - sans frais, bien sûr.",
    "What access will I have?": "Quel accès aurai-je ?",
    "We add all our clients as co-hosts on Airbnb. You’ll have full access to reservations, pricing, transaction history, and calendar availability.": "Nous ajoutons tous nos clients en tant que co-hôtes sur Airbnb. Vous aurez un accès complet aux réservations, aux tarifs, à l'historique des transactions et à la disponibilité du calendrier.",
    "What if we bring a client who is interested in renting our chalet at a time that it is not already rented (not family and friends)?": "Que faire si nous amenons un client intéressé par la location de notre chalet à un moment où il n'est pas déjà loué (pas de famille ni d'amis) ?",
    "It would be best to direct them to the Airbnb listing to build the reviews, be covered by Airbnb’s $1,000,000 insurance, and undergo rigorous KYC (Know Your Customer) checks.": "Il serait préférable de les diriger vers l'annonce Airbnb pour construire les avis, être couvert par l'assurance de 1 000 000 $ d'Airbnb, et passer des vérifications KYC (Connaître Votre Client) rigoureuses.",
    "How does your customer service work?": "Comment fonctionne votre service client ?",
    "Our team is available to answer any questions or inquiries at all times.": "Notre équipe est disponible pour répondre à toutes vos questions ou demandes à tout moment.",
    "How do you handle inventory reporting?": "Comment gérez-vous le rapport d'inventaire ?",
    "We will alert you when inventory is low (soap, toilet paper, etc.).": "Nous vous alerterons lorsque l'inventaire est faible (savon, papier toilette, etc.).",
    "How do you report damages?": "Comment signalez-vous les dommages ?",
    "In the rare case of damage from a guest, we handle the claims on your behalf with Airbnb’s Air Cover $1,000,000 insurance.": "Dans le cas rare de dommages causés par un invité, nous gérons les réclamations en votre nom avec l'assurance Air Cover de 1 000 000 $ d'Airbnb.",
    "Not what you are looking for? Feel free to contact us.": "Pas ce que vous recherchez ? N'hésitez pas à nous contacter.",
    "": "",
    "Experience the ": "Vivez l'Escapade",
    "Ultimate Getaway": "Ultime",
    "We offer more than just a place to stay.": "Nous offrons plus qu'un simple endroit où séjourner.",
    "Why Choose KŌZĒ?": "Pourquoi Choisir KŌZĒ ?",
    "Unmatched Comfort": "Confort Inégalé",
    "Every KŌZĒ property is thoughtfully designed with your": "Chaque propriété KŌZĒ est soigneusement conçue en pensant",
    "comfort in mind. From luxurious bedding to fully": "à votre confort. Des literies luxueuses aux",
    "equipped kitchens and cozy living spaces, we ensure that": " cuisines entièrement équipées et aux espaces de vie confortables,",
    "all the comforts of home are at your fingertips.": "nous veillons à ce que tous les conforts de la maison soient à votre portée.",
    "Stunning Locations": "Emplacements Éblouissants",

    "Our properties offer breathtaking views, serene surroundings, and": "Nos propriétés offrent des vues à couper le souffle, ",
    "easy access to local attractions. Whether you are seeking a": "des environs sereins et un accès facile aux attractions locales.",
    "peaceful retreat or an adventure-filled escape, KŌZĒ has the": "Que vous recherchiez un refuge paisible ou une escapade pleine",
    "perfect location for you.": "d'aventures, KŌZĒ a l'emplacement parfait pour vous.",


    "Seamless Stays": "Séjours Fluides",

    "We believe in making your stay as hassle-free as possible.": "Nous croyons en la simplification de votre séjour autant que",
    "With keyless entry, easy check-ins, and responsive": "possible. Avec un accès sans clé, des enregistrements faciles",
    "customer service, your experience with KŌZĒ is designed": " et un service client réactif, votre expérience avec KŌZĒ",
    "to be smooth and enjoyable from start to finish.": "est conçue pour être fluide et agréable du début à la fin.",

    "Luxurious Amenities": "Commodités Luxueuses",
    "Enjoy premium amenities such as hot tubs,": "Profitez de commodités premium telles que des bains à remous,",
    "fireplaces, and outdoor spaces that make your stay": "des cheminées et des espaces extérieurs qui rendent votre séjour spécial.",
    "special.": "spécial.",
    "Personalized": "Pérsonnalisé",
    "Recommendations": "Récommandations",
    "Get local tips and recommendations directly from": "Recevez des conseils et des recommandations locales",
    "our team to make the most of your stay.": "directement de notre équipe pour profiter au maximum de votre séjour.",
    "Flexible Booking": "Réservation Flexible",
    "Whether you are planning a weekend getaway or a": "Que vous planifiiez une escapade de week-end ou un",
    "longer stay, our flexible booking options cater to": "séjour plus long, nos options de réservation flexibles répondent à",
    "your needs.": "vos besoins.",
    "Explore Our Properties": "Explorez Nos Propriétés",
    "Discover the diverse range of properties KŌZĒ has to": "Découvrez la diversité des propriétés que KŌZĒ a à offrir, chacune",
    "offer, each with its own unique charm and character.": "avec son propre charme et caractère. Des cabanes confortables",
    "From cozy cabins to modern units, explore our listings to": "aux unités modernes, explorez nos annonces pour trouver",
    "find your perfect gateway.": "votre escapade parfaite.",
    "Quebec, Canada": "Québéc, Canada",
    "Toronto, Canada": "Toronto, Canada",
    "British Columbia, Canada": "Colombié-Britanniqué, Canada",
    "Dubai, United Arab Emirates": "Dubaï, Émirats Arabes Unis",
    "What You will Love": "Ce que Vous Allez Adorer",
    "Personal Guides": "Guides Personnels",
    "bookings, services, and more.": "résérvations, sérvicés ét plus éncoré.",
    "Please enter a valid email address": "S'il vous plaît, méttéz uné adréssé émail validé",
    "Thank you for signing up!": "Mérci dé votré inscription !",
    "Regions": "Régions",
    "Regions": "Régions",
    "SUBSCRIBE TO OUR NEWSLETTER": "Abonnéz-vous à la néwsléttér",
    "Manage": "Managé",
    "Subscribe": "S'abonnér",
    "5 Bedrooms | 16+ Guests": "5 Chambres | 16+ Invités",
    "1 Bedroom | 2 Guests": "1 Chambre | 2 Invités",
    "2 bedrooms | 8 Guests": "2 Chambres | 8 Invités",
    "3 bedrooms | 6 Guests": "3 Chambres | 6 Invités",
    "Black Creek, British Columbia": "Black Creek, Colombie-Britannique",
    "Explore all Stays": "Explorez Tous les Séjours",
    "Featured Stays": "Séjours en Vedette",
    "Avg rate":"Tarif Moyen",
    "Get On-Board": "Montez à Bord",
    "Check-in": "Arrivée",
    "Check-out": "Départ",
    "Select a city": "Sélectionnez une ville",
    "How does the check-in process work?": "Commént sé déroulé lé procéssus d'énrégistrémént ?",
    "We offer self-check-in using a secure code, which will be sent to you at 3 PM on the day of your check-in.": "Nous offrons un enregistrement autonome avec un code sécurisé, qui vous sera envoyé à 15 h le jour de votre enregistrement.",
    "What time is check-in?": "À quelle heure est l'enregistrement ?",
    "You can check in any time after 4:00 PM.": "Vous pouvez vous enregistrer à tout moment après 16 h.",
    "What time do we need to check out by?": "À quelle heure devons-nous quitter ?",
    "Check-out is any time before 11:00 AM.": "Le départ doit se faire avant 11 h.",
    "Can we smoke or have a party at the property?": "Pouvons-nous fumer ou faire une fête dans la propriété ?",
    "We have a strict no-party policy and no smoking allowed indoors. You may smoke outside, but it must be at least 8 meters away from any door or window. Please dispose of cigarette butts properly. Penalty fees will apply if cigarettes are found on the property.": "Nous avons une politique stricte d'interdiction de fêtes et il est interdit de fumer à l'intérieur. Vous pouvez fumer à l'extérieur, mais cela doit être à au moins 8 mètres de toute porte ou fenêtre. Veuillez jeter les mégots de cigarette correctement. Des frais de pénalité s'appliqueront si des cigarettes sont trouvées sur la propriété.",
    "Can we have visitors during our stay?": "Pouvons-nous avoir des visiteurs pendant notre séjour ?",
    "The property has a strict capacity limit, so no visitors are allowed during the day or overnight. If the capacity is exceeded, penalties may apply.": "La propriété a une limite de capacité stricte, donc aucun visiteur n'est autorisé pendant la journée ou la nuit. Si la capacité est dépassée, des pénalités peuvent s'appliquer.",
    "What should I do if the WiFi isn’t working?": "Que dois-je faire si le WiFi ne fonctionne pas ?",
    "Please unplug the router, wait 1 minute, then plug it back in. If this doesn’t fix the issue, feel free to message us for further assistance.": "Veuillez débrancher le routeur, attendre 1 minute, puis le rebrancher. Si cela ne résout pas le problème, n'hésitez pas à nous envoyer un message pour plus d'assistance.",
    "Can we park before check-in or after check-out?": "Pouvons-nous nous garer avant l'enregistrement ou après le départ ?",
    "Unfortunately, no. Our cleaning team needs access to the parking spots. However, if the unit is ready before check-in, we will notify you.": "Malheureusement, non. Notre équipe de nettoyage a besoin d'accès aux places de stationnement. Cependant, si l'unité est prête avant l'enregistrement, nous vous en informerons.",
    "What should we do with the towels and linens when checking out?": "Que devons-nous faire avec les serviettes et les draps lors du départ ?",
    "Please leave the beds unmade, and place used towels in the bathtub.": "Veuillez laisser les lits non faits et placer les serviettes usées dans la baignoire.",
    "Where should we put our garbage?": "Où devons-nous mettre nos ordures ?",
    "Please place your garbage in the outdoor bins located near the property.": "Veuillez mettre vos ordures dans les bacs extérieurs situés près de la propriété.",
    "Is early check-in or late check-out available?": "Un enregistrement anticipé ou un départ tardif est-il disponible ?",
    "Please message us on the day of your check-in/check-out to confirm availability. If possible, we’d be happy to offer it.": "Veuillez nous envoyer un message le jour de votre enregistrement/départ pour confirmer la disponibilité. Si possible, nous serions ravis de l'offrir.",
    "Can I cancel my reservation and get a refund?": "Puis-je annuler ma réservation et obtenir un remboursement ?",
    "Cancellations are handled through the platform you reserved on. Please contact them directly for any refund requests, as we cannot manage refunds on our end.": "Les annulations sont gérées par la plateforme sur laquelle vous avez réservé. Veuillez les contacter directement pour toute demande de remboursement, car nous ne pouvons pas gérer les remboursements de notre côté.",
    "Do you have other listings we can see?": "Avéz-vous d'autrés annoncés qué nous pouvons voir ?",
    "Click Here": "Cliquez ici",
    "Yes! You can check out our other listings on our Airbnb profile :": "Oui ! Vous pouvéz consultér nos autrés logéménts sur notré profil Airbnb :",
    "Are pets allowed?": "Les animaux sont-ils autorisés ?",
    "Unfortunately, we have a strict no-pets policy due to allergy concerns.": "Malheureusement, nous avons une politique stricte d'interdiction des animaux de compagnie en raison de problèmes d'allergies.",
    "Is there a minimum or maximum stay length?": "Y a-t-il une durée minimale ou maximale de séjour ?",
    "The minimum stay is 2 nights, but there is no maximum.": "Le séjour minimum est de 2 nuits, mais il n'y a pas de maximum.",
    "BRITISH COLUMBIA": "COLOMBIE-BRITANNIQUE",
    "Yes! You can check out our other listings on our Airbnb profile: Click Here.": "Oui! Vous pouvez consulter nos autres annonces sur notre profil Airbnb : Cliquez ici.",
    "Not what you are looking for? Feel free to": "Pas ce que vous recherchez ? N'hésitez pas à",
    "Contact us.":"Nous Contacter.",
    "1. Check-In/Out and Parking": "1.Enregistrement/départ et stationnement",
    "2. Policies and Property Rules": "2. Politiques et Règles de Propriété",
    "3. Amenities and Housekeeping": "3. Commodités et Ménage",
    "Host FAQs": "FAQ sur les hôtes",
    "FAQs - Guests": "FAQ - Invités",
    "Jess": "Jess",
    "Marc": "Marc",
    "Henry": "Henry",
    "Sara": "Sara",
    "Jeffery Khoury": "Jeffery Khoury",
    "President": "Président",
    "Stephanie Lammam": "Stephanie Lammam",
    "Executive Vice President": "Vice-présidente Exécutive",
    "Cyrille El-Kahi": "Cyrille El-Kahi",
    "Mortgage Broker": "Courtier Hypothécaire",
    "Jonathan Massimo Stinziani": "Jonathan Massimo Stinziani",
    "Director of Operations": "Directeur des Opérations",
    "Euro(€)": "Éuro(€)",
    "Leave it to the KŌZĒ team":"Laissez l'équipe KŌZĒ débloquer ",
    "to unlock the full potential of your ":"le plein potentiel de votre",
    " property":"propriété",

    "SMS Privacy Policy":"Politiqué dé confidéntialité dés SMS",
    "By opting in to receive SMS messages from KŌZĒ, you consent to receive marketing and informational text messages, including promotions, updates, and offers related to our services. Message frequency may vary.":"En choisissant dé récévoir dés méssagés SMS dé KŌZĒ, vous conséntéz à récévoir dés méssagés téxté markéting ét informatifs, y compris dés promotions, dés misés à jour ét dés offrés liéés à nos sérvicés. La fréquéncé dés méssagés péut variér.",
    "Data Usage : ":"Utilisation dés donnéés : ",
    "We collect and use your mobile number solely for the purpose of sending SMS communications you have agreed to receive. Your number will not be sold or shared with third parties for their marketing purposes.":"Nous colléctons ét utilisons votré numéro dé mobilé uniquémént dans lé but d'énvoyér dés communications SMS qué vous avéz accépté dé récévoir. Votré numéro né séra pas véndu ou partagé avéc dés tiérs à dés fins dé markéting.",
    "Opt-Out : ":"Se déséngagér : ",
    "You may opt-out of SMS communications at any time by replying STOP to any message or contacting us directly. Once you opt-out, you will no longer receive SMS communications unless you re-subscribe.":"Vous pouvéz vous désinscriré dés communications SMS à tout momént én répondant STOP à n'importé quél méssagé ou én nous contactant diréctémént. Uné fois désinscrit, vous né récévréz plus dé communications S",
    "Standard Messaging Rates : ":"Tarifs dé méssagérié standard : ",
    "Standard message and data rates may apply based on your mobile carrier’s policies.":"Dés tarifs standard pour lés méssagés ét lés donnéés péuvént s'appliquér én fonction dés politiqués dé votré opératéur dé téléphonié mobilé.",
    "Security : ":"Sécurité : ",
    "We take measures to protect your personal information, including your mobile number, against unauthorized access and use.":"Nous prénons dés mésurés pour protégér vos informations pérsonnéllés, y compris votré numéro dé téléphoné mobilé, contré tout accès ét utilisation non autorisés.",
    "KŌZĒ reserves the right to amend these terms at any time. Users will be notified of changes via email. Continued use of our services constitutes acceptance of the amended terms.":"KŌZĒ sé résérvé lé droit dé modifiér lés préséntés conditions à tout momént. Lés utilisatéurs séront informés dés modifications par é-mail. L'utilisation continué dé nos sérvicés constitué l'accéptation dés conditions modifiéés.",



    "SMS Terms and Conditions":"Conditions généralés dé vénté dés SMS",
    "By opting in to receive SMS communications from KŌZĒ, you agree to the following terms:":"En choisissant dé récévoir dés communications SMS dé KŌZĒ, vous accéptéz lés conditions suivantés:",
"Message Frequency : ":"Fréquéncé dés méssagés : ",
"You may receive recurring automated marketing and informational messages, including updates, promotions, and offers.":"Vous pouvéz récévoir dés méssagés markéting ét informatifs automatisés récurrénts, notammént dés misés à jour, dés promotions ét dés offrés.",
"Consent : ":"Conséntémént : ",
"By providing your mobile number, you consent to receive these messages. Consent is not required as a condition of purchase.":"En fournissant votré numéro dé mobilé, vous conséntéz à récévoir cés méssagés. Lé conséntémént n’ést pas réquis commé condition d’achat.",
"You may opt out of SMS communications at any time by replying STOP to any message or contacting us directly. Once you opt out, you will no longer receive SMS messages unless you re-subscribe.":"Vous pouvéz vous désinscriré dés communications SMS à tout momént én répondant STOP à n'importé quél méssagé ou én nous contactant diréctémént. Uné fois désinscrit, vous né récévréz plus dé SMS, sauf si vous vous réabonnéz.",
"Data and Charges : ":"Donnéés ét frais : ",
"Message and data rates may apply based on your mobile carrier’s policies.":"Dés tarifs dé méssagérié ét dé donnéés péuvént s'appliquér én fonction dés politiqués dé votré opératéur dé téléphonié mobilé.",
"Privacy : ":"Confidéntialité : ",
"Your phone number and personal information are protected in accordance with our privacy policy and will not be sold or shared with third parties for marketing purposes.":"Votré numéro dé téléphoné ét vos informations pérsonnéllés sont protégés conformémént à notré politiqué dé confidéntialité ét né séront pas véndus ou partagés avéc dés tiérs à dés fins dé markéting.",

"Experience the charm and natural beauty of Quebec’s Laurentians at our exceptional properties. Nestled amidst serene landscapes, our Laurentian properties offer a harmonious blend of modern comfort and local character. Whether you’re seeking a peaceful retreat in the mountains, outdoor adventures, or a taste of the region’s vibrant culture, our properties are thoughtfully designed to immerse you in the true essence of the Laurentians.":"Découvréz lé charmé ét la béauté naturéllé dés Lauréntidés au Québéc dans nos propriétés éxcéptionnéllés. Nichéés au cœur dé paysagés séréins, nos propriétés dés Lauréntidés offrént un mélangé harmoniéux dé confort modérné ét dé caractèré local. Qué vous réchérchiéz uné rétraité paisiblé dans lés montagnés, dés avénturés én pléin air ou un avant-goût dé la culturé dynamiqué dé la région, nos propriétés sont soignéusémént conçués pour vous immérgér dans la véritablé ésséncé dés Lauréntidés.",
"Laurentians":"Lauréntidés"
  },
};
export default fr;
