import React, { useState } from "react";
import "./CustomDropDownStyle.css";
import Icons from "../../Constants/Icons";
import { useTranslation } from "react-i18next";
function CustomDropdown({ items, onSelect, dropDownLabel, customLabelStyle ,isDisabled}) {
  const [isOpen, setIsOpen] = useState(false);
  const [cur_section, setCurSection] = useState('')
  const toggleDropdown = () => {
    if(isDisabled != true){
      setIsOpen(!isOpen);
    }
  };
  const handleItemClick = (item) => {
    setIsOpen(false);
    onSelect(item);
    setCurSection(item)
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  const { t } = useTranslation();
  return (
    <div
      onMouseLeave={() => handleMouseLeave()}
      onClick={toggleDropdown}
      className="custom-dropdown"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 26,
          gap:"6px"
        }}
      >
        <div
          onClick={toggleDropdown}
          style={customLabelStyle}
          className="custom-dropdown2 "
        >
          {t(dropDownLabel)}
        </div>
        <div className={` ${isOpen ? 'validmenuround' : 'menuround'}`} style={{ display: "flex" }}>
        {isDisabled != true &&   <img className="downArrowIcon" alt="" src={Icons.Arror}  style={{width:"15px"}}/> }
        </div>
      </div>
      {isOpen && (
        <div style={{ position: "absolute", width: "100%",left:"0px" }}>
          <div style={{ height: 10 }}></div>
          <ul  className="dropdown-menu">
            {items.map((item) => (
              <li  className={item === cur_section ? "": ""} key={item} onClick={() => handleItemClick(item)}>
                {t(item)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
