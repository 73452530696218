import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { setLoadingFalse } from "../Config/Actions/loadingActions";
import { getDesignId } from "../Components/CustomCanvas/CustomCanvas";

const PDFViewer = () => {
  const { propertyName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const designId = getDesignId(propertyName);
  

  const fileUrl = `https://www.canva.com/design/${designId}/view?embed`;
console.log("fileUrl",fileUrl)

  useEffect(() => {
    if (designId == "DEFAULT_ID") {
      onNavigateHome();
    }
  }, []);

  const onNavigateHome = () => {
    navigate("/");
  };
  const onDocumentLoad = () => {
    dispatch(setLoadingFalse());
  };
  return (
    <div
      className="pdf-viewer"
      style={{
        height: "calc(100vh - 50px)",
        width: "100%",
        backgroundColor: "#000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          width: "80%",
          maxWidth: "1200px",
          height: "100%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#000",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <iframe
          src={fileUrl}
          allowFullScreen
          height="100%"
          
          width="100%"
          style={{ background: "#000" }}
          onLoad={onDocumentLoad}
          
        ></iframe>
      </div>
    </div>
  );
};

export default PDFViewer;
