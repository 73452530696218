const designMappings = {
    "Chalet_St_Come_Villa_Nature": "DAGW5x5PdEE/e2azC3f5avoXXyu2o5mxYw",
    "Verbier_101": "DAGW5_ytP9U/HH9bkvohJzZhV01Uqs3oZQ",
    "2157_DDLM_Tremblant":"DAGYL394Z6I/ln9aY-2G9zSz-OSi568DsQ",
    "2133_DDLM_Tremblant":"DAGYME1Fvz4/jKSfcQaBHKVTPm6lCdrSDg",
"Solana_koze":"DAGW6JvpKLI/W4eriowwQNChKDkGZKv84A",
"1906":"DAGW58uPP_s/NGg6zc8Rrf4M3-W4EL0_wQ",
"Hotel_St_Sauveur_3103":"DAGYME39a94/3HOfbibDTvobFz4cG5i-NA",
"Hotel_Saint_Sauveur_3102":"DAGYMJaQntU/45v_2GjpJwBwfPdtx5XS7Q",
"3202_Hotel_St_Sauveur":"DAGYMQ9anaA/r4iiSMTYyTCV-JlexaRKVQ",
"3105_Hotel_St_Sauveur":"DAGYMYJe9Ig/GHt6ESuRNF2ErIeupmKzbg",
"Mont_Blanc_Waterfront":"DAGYMejlbNg/Ns24_Nf4oF_OgSKeOm4TEA",
"Rabaska_Tremblant_321":"DAGW6MtuHgA/mXLY3q1-ZHHcHvBWYMvoWw",
"Orizon_210":"DAGW6IqsrCY/yhAKXMsau7HcF_hMjA-RbA",
"Myles_Chertsey":"DAGYMsN1w1c/9pg-NNcJ84Mt8uq4hcdUMw",
"Muses":"DAGW6B7OxtM/fwNgxdhQtmJRX-qDg4faQg",
"429_Hotel_du_Lac_Tremblant":"DAGYRCE3bsA/krf6by0Y8Y02J3bJ0WtZ6g",
"431_Hotel_du_Lac_Tremblant":"DAGYRTItPGc/9QNuNFqOO8-OSiVypCt1-g",
"470_Hotel_du_Lac":"DAGYRTAqN4g/GJDPKmRh1qQozrRbCXYohg",
"481_Hotel_du_Lac_tremblant":"DAGYRXBNwIk/myBMSewF0G4IDdpovCod9Q",
"206_Hotel_du_Lac_Tremblant":"DAGYSFnguSc/G_ZVwsNj95juD4GqjsSWvA",
"1B_Mont_Blanc_111_02":"DAGZo3wQ0BA/7dT-88cmOb6R6hP9VRiTMg",
"Panache_Chalet":"DAGW6FkTyYk/2FwoiPnFvOzsMkBmZbVQ1Q",
"2B_111_08_Mont_Blanc":"DAGYSxzf1p8/z1o250h05rh-CEFtmdnJzA",
"2B_Mont_Blanc_111_04":"DAGZ-_ooWLg/0mEN9rkn8VIaNCqmvGvmbg",
"206_Cap_Tremblant":"DAGYT4Ra4wE/KgqJvm6c2PQPjNeiQxi-YA",
"848_Coupal":"DAGYSseYBWY/eZUEMb7eZmBTntXcSmXieg",
"872_Coupal":"DAGYYLJUJeU/pmwU4vqN3haGAOxDdTWd-A",
"1653_La_Bete_Tremblant":"DAGYYA2CzFk/zSkvbLz0QUJwEdGi3dv_qg",
"223_2_les_eaux_Tremblant":"DAGYYAHEiOk/AoMriEQW16SsmKuUMxy70g",
"1753_La_Bete_Tremblant":'DAGYYPpJkDk/bitBqHItST1SAwfTzLDDSw',
"1804_La_Bete_Tremblant":"DAGYYSsXEh8/cORFChZW4aBsilgMHrdERw",
"Andrea_KOZE":"DAGYYspZqY0/JozvYJTejmSbHFX3Y0Tlfw",
"2097_DDLM_Tremblant":"DAGYYlfo11k/PFaYoQeSywzVYCrx_1ZNHw",
"2137_DDLM_Tremblant":"DAGYY1QLYRg/tBqxvn_kkRe2FYOF_Jh_Sw",
"Chertsey_Chalet":"DAGYY9ja37A/r2LuNTJ-yIT0nrtTD-xSuQ",
"56_Village_Mont_Blanc":"DAGYY629ZWw/JSGKW5QEFu8XSvzTeEzGDg",
"2113_DDLM_Tremblant":"DAGYeU4vY8Q/Z9FdAXDJVdFyIc7Ij_5WLg",
"Mont_Plaisant_Tremblant_107":"DAGYeVZWwPI/TQsWh2IfZciuC6MjJcic2A",
"Lac_Saint_Paul":"DAGYeTO7mo4/e-3SRlks8F3PiXuDi4m8nw",
"Saratoga_A23":"DAGW54G-TPU/y_TPzsKzsVYzdaTr5iyCtA",
"Saratoga_Beach_6":"DAGW-Y0CzfI/iiMtJ8fabqEH1MLZkkLGNQ",
"Saratoga_Beach_16":"DAGW-GTBIsg/ssucd7js7IwE7tB3vvehyw",
"Cap_Tremblant_290_Unit_8_Daniel":"DAGYez-VxXY/H7zAZLQFmx2b1EQXy_jlSQ",
"Lac_Superieur_223":"DAGYe_Oa25o/ZHYjjP7wUSNwvFkrMAtsBw",
"Chalet_Brothers":"DAGYe1guO3g/sBh4uPJxlFjxU6C4rBjUWg",
"Etoile_du_Matin_Tremblant":"DAGYe3KllIA/UrSOCIJ5WpDz4oer-4ZeDg",
"1313_Mont_Blanc":"DAGYe8IG3ec/NrYpZPhRGkRftifkpIxVmA",
"St_Laurent_MONTREAL":"DAGYez-VxXY/H7zAZLQFmx2b1EQXy_jlSQ",
"F-131":"DAGY3Zt4_1k/kNayXKgfUx8OeKng6zjDWA",
"Hotel_du_Lac_Tremblant_426_Bloc":"DAGY3kf8NU0/2N5hPc5irLHJP1gSjVom2g",
"Hotel_du_Lac_Tremblant_Bloc":"DAGY3j_c5Ao/xgQsi3SscXrTTlR482thww",
"Lac_Superieur_Golf_125":"DAGY3p8yMIk/x2WL3JfJTTPKlldNJEy_nQ",
"Lac_Superieur_304":"DAGY32bg_sw/JuqactrVGPbYbyU_lYpnOw",
"526_Courchevel":"DAGY4IPWxW4/TlAgqvbWvV2-fIC4vQixdw",
"Mont_Plaisant_Tremblant_304_Bloc":"DAGY4QAE8Ak/prxFWGcM5W774ijrwskaWw",
"2142_DDLM":"DAGY91gohfg/WJmYdjE0-TUgNXxYBciLGA",
"Five_Palm_Luxury_Residence_Palm":"DAGY9_Zn8pI/wSDG2kiwGDTopS6gV4ed-Q",
"Mont_Royal_unit_2":"DAGY-BafZRM/0JEwfphL-LdjBFkQw3nxEw",
"Mont_Royal_unit_1":"DAGY-UJkE6w/SRq8-ZR9b7XCdidz54HU4A",
"Wellington":"DAGY-St2KAE/Qpd-9Ob3NRYlTRFzjVfDSQ",
"Waterfront_View":"DAGY-o8CPI0/bS3JQVdCTQr6u-6HuErVpA",
"Les_Eaux_Trmblant_223_3":"DAGY-uw_V-Q/_SArJjFE6tgs2UbyfxU_HQ",
"Les_Manoirs_Tremblant_112_Unit_9":"DAGY-of0jRQ/5JsyufDrGIQUzEvG_Pf88w",
"HResidence_3461_Ontario":"DAGZDEgA_m8/Fa8XCZp1IQoXhAYlar1ZLQ",
"Les_Falaises_Tremblant_134_9":"DAGZDHHD_hM/gK8WzE4glLXPFExmANVm8A",
"2119_DDLM_Tremblant":"DAGZDUMEBK8/_L6A1oVT5P0ELTgw4V7R2Q",
"1806_La_Bete_Tremblant":"DAGZDUqEKco/EuB7aTGxCWj9wIUkgZpI9w",
"119_2190_DDLM_Tremblant":"DAGZDgbqSNg/NzoJXX-Isj2w-TLbhsGMuQ",
"106_Mont_plaisant_TREMBLANT":"DAGZDiR87Ig/6A_JZ2GKphU9vhMtWTUSBQ",
"105_Mont_Plaisant_TREMBLANT":"DAGZDy1YjOw/N7v5hSqsbwPY1Sqttj6oYA",
"Bel_Air_10_Mont_Blanc":"DAGZJaQHUDo/-06IYppWZg9znSfqIG4ChQ",
"St_Adolphe_Chalet_Long_Term":"DAGZJb47m1c/rspgmdF20GClHQPcoJy98g",
"Le_Havre_Spa_Sauna":"DAGZJedOH5g/BVzmYrbBvgzZvRQXX3DmYg",
"The_lima":"DAGZJtQKSSQ/yz9FeeCs61S4Q5W6prhIng",
"The_hilda":"DAGZJrUl8O4/LAbIxCh90YchYb2-1dVHuw",
"Tarzan":"DAGZJljzPTc/exkkznm069gZPVa-EReECw",
"Rosas_Nest_LakeSpaNature":"DAGZJgaMVVI/KS0agtrMFBsdUcx7omPviQ",
"Riverine":"DAGZJh6X5bk/hQa7HmjISmvYkkjzx8tNuQ",
"St_Lucie":"DAGW57Hdxvo/AEJeeqecEOpyhRCpO-74Ug",
"Royal_Laurentian":"DAGZJuF1524/oAxZ7N5xT2_nObtBboOJ6g",
"Log_Cabin":"DAGZJwRXHkQ/fXeLsDN-Tg5QSOy4u8MByQ",
"Huron_half":"DAGZJ9LHgoA/DTtBNum6oWbnNViJBbuQqQ",
"Hotel_Saint_Sauveur_3108":"DAGZOdUF3tw/COm0A9dCuP8Fu6w4ukVIcg",
"Hotel_Saint_Sauveur_3109":"DAGZOXbHY6c/cLloB2u1HSLEQwiGXjJSWw",
"Huron_Full":"DAGZO5uvo8M/8xvRuVIYB6uthx4CjCxncg",
"HIBOUx_Nature_Spa_Mont_Tremblant":"DAGZO8D0dOM/3suEa9yv-WSHBis_ewCy2w",
"Chalet_totem":"DAGZPLBVJGg/B1aO7xLOoNFCffSh0bgJcw",
"Chalet_Familia_full_house":"DAGZPIKPkVo/SRhm14XD6vadnWoy5_yPXA",
"Bord_du_lac_upper_level":"DAGZPNu_V2o/-g8_ONi96edo1v0dJ1W1hg",
"Bord_du_lac_full_house":"DAGZPNEXPOA/cTl-givxntyZKNs_Br1Mjw",
"2B_111_11_Mont_Blanc_France":"DAGZPDKcFmU/4P2KdNPo6bLLq-KsucOyIQ",
"Blue_Mountain":"DAGZh8QgEY4/AuSAWvUO31RYAn2Otgi0Nw"
  };
  export const getDesignId = (propertyName) => {
    return designMappings[propertyName] || "DEFAULT_ID"; 
  };
  